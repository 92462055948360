<script lang="ts">
  import VideoBackground from "$lib/ui/common/VideoBackground.svelte";
  import GetStartedOrBookDemo from "../GetStartedOrBookDemo.svelte";
</script>

<div class="marketing-container">
  <div
    class="flex flex-col items-center md:items-start lg:flex-row gap-x-12 justify-between mt-24"
  >
    <h1 class="marketing-h1 mb-6 text-center md:text-left shrink-0">
      High-performance<br /><span class="text-primary">AI infrastructure</span>
    </h1>
    <div class="max-w-80 text-lg text-center md:text-left lg:mt-4 xl:mr-4">
      Serverless cloud for AI, ML, and data applications &ndash; built for
      developers
    </div>
  </div>

  <div class="flex gap-4 justify-center md:justify-start mt-8">
    <GetStartedOrBookDemo />
  </div>

  <div
    class="mt-4 md:-mt-24 lg:-mt-32 xl:-mt-40 mb-8 md:mb-12 marketing-contained-negate-ml marketing-contained-negate-mr"
  >
    <!-- transform-style: preserve-3d fixes a subpixel aliasing bug in Firefox -->
    <div
      class="relative -z-10 w-full scale-[140%] aspect-[1600/910]"
      style="transform-style: preserve-3d"
    >
      <VideoBackground poster="https://modal-cdn.com/tmp60ydwzz8_ea31268d.webp">
        <source
          src="https://modal-cdn.com/landscape-vids/Modal_Hero_3MB.mp4"
          type="video/mp4"
        />
      </VideoBackground>
    </div>
  </div>
</div>
