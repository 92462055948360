<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import VideoBackground from "$lib/ui/common/VideoBackground.svelte";
  import MarketingButton from "../MarketingButton.svelte";

  let viewportHeight = 0;
  let scrollerHeight = 0;
  let currentVideoIndex = -1;

  const videos = [
    {
      topHEVCSrc:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part1_Only_UI-hevc-safari.mp4",
      topVP9Src:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part1_Only_UI-vp9-chrome.webm",
      bottomSrc:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part1_No_UI.mp4",
      poster: "https://modal-cdn.com/tmpqemq5_7y_40c13ce7.webp",
    },
    {
      topHEVCSrc:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part2_Only_UI-hevc-safari.mp4",
      topVP9Src:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part2_Only_UI-vp9-chrome.webm",
      bottomSrc:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part2_No_UI.mp4",
      poster: "https://modal-cdn.com/tmp1__y6h68_b7d80106.webp",
    },
    {
      topHEVCSrc:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part3_Only_UI-hevc-safari.mp4",
      topVP9Src:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part3_Only_UI-vp9-chrome.webm",
      bottomSrc:
        "https://modal-cdn.com/landscape-vids/Modal_Sequence_Part3_No_UI.mp4",
      poster: "https://modal-cdn.com/tmpccmwguat_bcf14b78.webp",
    },
  ];

  let sections: HTMLElement[] = [];

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sections.indexOf(entry.target as HTMLElement);
            if (index !== -1) {
              currentVideoIndex = index;
            }
          }
        });
      },
      {
        root: null,
        rootMargin: "-50% 0px -50% 0px", // Trigger when section is in the middle 50% of the viewport
        threshold: 0,
      },
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  });
</script>

<svelte:window bind:innerHeight={viewportHeight} />

<div class="my-20 padding-bottom-on-short-screens">
  <div
    class="marketing-container grid grid-cols-1 gap-y-36 lg:gap-y-0"
    bind:clientHeight={scrollerHeight}
  >
    <div class="section" bind:this={sections[0]}>
      <div class="text-section">
        <h2 class="marketing-h3">
          Cloud development made
          <span class="text-brand-green">frictionless</span>
        </h2>

        <div class="mobile-video">
          <VideoBackground
            poster={videos[0].poster}
            class="row-start-1 col-start-1 max-h-80"
          >
            <source src={videos[0].bottomSrc} type="video/mp4" />
          </VideoBackground>

          <VideoBackground class="row-start-1 col-start-1 max-h-80">
            <source
              src={videos[0].topHEVCSrc}
              type={`video/mp4; codecs="hvc1"`}
            />
            <source src={videos[0].topVP9Src} type="video/webm" />
          </VideoBackground>
        </div>

        <p>
          Run generative AI models, large-scale batch jobs, job queues, and much
          more. Bring your own code — we run the infrastructure.
        </p>
        <MarketingButton href="/docs/guide/images">View Docs</MarketingButton>
      </div>

      <figure class="hidden lg:block lg:relative">
        <!-- Dynamic height is used to ensure video fits within container -->
        <div
          class="absolute top-0 left-0 w-full"
          style="height: {scrollerHeight - viewportHeight * 0.6 - 120}px"
        >
          <div class="sticky-animation">
            {#each videos as video, index}
              {#if currentVideoIndex === index}
                <div class="w-full relative" transition:fade>
                  <VideoBackground inset poster={video.poster}>
                    <source src={video.bottomSrc} type="video/mp4" />
                  </VideoBackground>

                  <VideoBackground inset>
                    <source
                      src={video.topHEVCSrc}
                      type={`video/mp4; codecs="hvc1"`}
                    />
                    <source src={video.topVP9Src} type="video/webm" />
                  </VideoBackground>
                </div>
              {/if}
            {/each}
          </div>
        </div>
      </figure>
    </div>

    <div class="section" bind:this={sections[1]}>
      <div class="text-section">
        <h2 class="marketing-h3">
          Iterate at the <span class="text-brand-green">speed</span> of thought
        </h2>

        <div class="mobile-video">
          <VideoBackground
            poster={videos[1].poster}
            class="row-start-1 col-start-1 max-h-80"
          >
            <source src={videos[1].bottomSrc} type="video/mp4" />
          </VideoBackground>

          <VideoBackground class="row-start-1 col-start-1 max-h-80">
            <source
              src={videos[1].topHEVCSrc}
              type={`video/mp4; codecs="hvc1"`}
            />
            <source src={videos[1].topVP9Src} type="video/webm" />
          </VideoBackground>
        </div>

        <p>
          Make code changes and watch your app rebuild instantly. Never write a
          single line of YAML again.
        </p>
        <MarketingButton
          href="/docs/guide/webhooks#developing-with-modal-serve"
        >
          View Docs
        </MarketingButton>
      </div>
    </div>

    <div class="section" bind:this={sections[2]}>
      <div class="text-section">
        <h2 class="marketing-h3">
          Built for <span class="text-brand-green">large-scale</span> workloads
        </h2>

        <div class="mobile-video">
          <VideoBackground
            poster={videos[2].poster}
            class="row-start-1 col-start-1 max-h-80"
          >
            <source src={videos[2].bottomSrc} type="video/mp4" />
          </VideoBackground>

          <VideoBackground class="row-start-1 col-start-1 max-h-80">
            <source
              src={videos[2].topHEVCSrc}
              type={`video/mp4; codecs="hvc1"`}
            />
            <source src={videos[2].topVP9Src} type="video/webm" />
          </VideoBackground>
        </div>
        <p>
          Engineered in Rust, our custom container stack allows you to scale to
          hundreds of GPUs and then back down to zero in seconds. Pay only while
          it's running.
        </p>
        <MarketingButton href="/docs/guide/scale">View Docs</MarketingButton>
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  @media (max-height: 800px) {
    .padding-bottom-on-short-screens {
      padding-bottom: calc(600px - 75vh);
    }
  }

  .mobile-video {
    @apply my-12 lg:hidden relative h-full grid;
  }

  .sticky-animation {
    @apply sticky;
    top: calc(50% - (586px / 2));
  }

  .section {
    @apply grid-cols-1;

    @screen lg {
      @apply grid grid-cols-[400px_1fr] gap-x-40 h-[70vh];
    }
  }

  @screen lg {
    .text-section {
      margin-top: 200px;
    }
  }

  .text-section > h2 {
    @apply mb-6 max-w-[16ch];
  }

  .text-section > p {
    @apply mb-9;
  }
</style>
